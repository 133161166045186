import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getHosthistory } from "../store/history/history.action";
import InfiniteScroll from "react-infinite-scroll-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";

const HostHistory = () => {
  const { hostHitory, total } = useSelector((state) => state.history);

  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  const hostId = location?.state?.state;

  useEffect(() => {
    dispatch(getHosthistory(hostId, startDate, endDate));
  }, [hostId, activePage, rowsPerPage, startDate, endDate]);

  const fetchData = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        if (hostHitory?.length < total) {
          setActivePage(activePage + 1);
          setRowsPerPage(rowsPerPage + 10);
          setLoadingMore(false);
        } else {
          setHasMore(false);
        }
      }, 500); // Adjust delay as needed
    }
  };

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    dispatch(getHosthistory(hostId, startDate, endDate));
  };

  return (
    <>
      <div class="page-content">
        <div class="main-wrapper">
          <div className="main-section p-2">
            <div
              className="row mb-2 bg-white p-3"
              style={{ position: "fixed", width: "100%", top: "0" }}
            >
              <div className="col-4 d-flex align-items-center">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => window.history.back()}
                >
                  <path
                    d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="col-4 text-center">
                <p
                  className="text-dark fw-bold mb-0"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  History
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginTop: "65px" }}
            >
              <h4
                className="text-dark fw-bold pt-2 mt-2"
                style={{ fontSize: "16px" }}
              >
                Creator History
              </h4>
              <div>
                <div className="date-picker">
                  <div className="date-picker d-flex justify-content-end ms-auto">
                  <DateRangePicker
                      initialSettings={{
                        startDate: undefined,
                        endDate: undefined,
                        ranges: {
                          All: [new Date("1970-01-01"), dayjs().toDate()],
                          Today: [dayjs().toDate(), dayjs().toDate()],
                          Yesterday: [
                            dayjs().subtract(1, "days").toDate(),
                            dayjs().subtract(1, "days").toDate(),
                          ],
                          "Last 7 Days": [
                            dayjs().subtract(6, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "Last 30 Days": [
                            dayjs().subtract(29, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "This Month": [
                            dayjs().startOf("month").toDate(),
                            dayjs().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            dayjs()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            dayjs()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                        },
                        maxDate: new Date(),
                        singleDatePicker: false,
                        linkedCalendars: false,
                      }}
                      // onCallback={handleCallback}
                      onApply={handleApply}
                    >
                      <input
                        type="text"
                        readOnly
                        placeholder="Select Date Range"
                        // onClick={handleInputClick}
                        className={`daterange float-right mr-4 text-center pt-0 pb-0`}
                        value={
                          startDate && endDate
                            ? `${dayjs(startDate).format(
                                "MM/DD/YYYY"
                              )} - ${dayjs(endDate).format("MM/DD/YYYY")}`
                            : "Select Date Range"
                        }
                        style={{
                          // width: "85%",
                          fontWeight: 500,
                          cursor: "pointer",
                          background: "#F0F0F0",
                          color: "#000",
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                          fontSize: "13px",
                          padding: "10px",
                          maxWidth: "226px",
                          borderRadius: "64px",
                          border: "1px solid transparent",
                          height: "48px !important",
                          outline: "none",
                        }}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>

            <div className="agency-detail">
              {hostHitory?.length > 0 ? (
                <div className="row">
                  {hostHitory?.map((data) => {
                    return (
                      <>
                        <div className="col-12 mb-2">
                          <div className="agency-invitation bg-white p-2">
                            <div className="row">
                              <div className="col-4 text-center">
                                <span
                                  style={{
                                    color: "#a7a7a7",
                                    fontSize: "8px",
                                  }}
                                >
                                  Coin
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={require("../assets/images/rcoin.png")}
                                    style={{ height: "15px", width: "15px" }}
                                    alt=""
                                  />
                                  <span
                                    className="mb-0 ms-1 fw-bolder"
                                    style={{
                                      color: "#ff8300",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.coin ? data?.coin : 0}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 text-center">
                                <span
                                  style={{
                                    color: "#a7a7a7",
                                    fontSize: "8px",
                                  }}
                                >
                                  Bonus
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={require("../assets/images/rcoin.png")}
                                    style={{ height: "15px", width: "15px" }}
                                    alt=""
                                  />
                                  <span
                                    className="mb-0 ms-1 fw-bolder"
                                    style={{
                                      color: "#ff8300",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.hostBonus ? data?.hostBonus +  `(${data?.hostBonusPercent + "%"})`  : 0}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 text-center">
                                <span
                                  style={{
                                    color: "#a7a7a7",
                                    fontSize: "8px",
                                  }}
                                >
                                  Total
                                </span>
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={require("../assets/images/rcoin.png")}
                                    style={{ height: "15px", width: "15px" }}
                                    alt=""
                                  />
                                  <span
                                    className="mb-0 ms-1 fw-bolder"
                                    style={{
                                      color: "#ff8300",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.totalCoin ? data?.totalCoin   : 0}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 text-center">
                                <span
                                  style={{
                                    color: "#a7a7a7",
                                    fontSize: "8px",
                                  }}
                                >
                                  Start Date
                                </span>
                                <div className="d-flex align-items-center justify-content-center">

                                  <span
                                    className="mb-0 ms-1 fw-bolder"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.startDate}
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 text-center">
                                <span
                                  style={{
                                    color: "#a7a7a7",
                                    fontSize: "8px",
                                  }}
                                >
                                  End Date
                                </span>
                                <div className="d-flex align-items-center justify-content-center">

                                  <span
                                    className="mb-0 ms-1 fw-bolder"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {data?.endDate}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center my-4">
                  <span>No data found</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostHistory;
