import React, { useRef } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createCoinData } from "../../store/seller/seller.action";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import { useSelector } from "react-redux";
import * as ActionType from "../../store/seller/seller.type";
import { useEffect } from "react";
import Select from "react-select";
import { createHost, getCoinSellerUniqueId } from "../../store/user/action";

function CoinSeller(props) {
  const { dialog, dialogData } = useSelector((state) => state.sellerCoin);

  const { userId } = useSelector((state) => state.user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const agencyId = localStorage.getItem("agencyId");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [errors, setError] = useState("");

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: ActionType.CLOSE_COIN_SELLER_DIALOGUE });
  };

  useEffect(() => {
    setError({
      userId: "",
      coin: "",
    });
  }, [dialog]);

  useEffect(() => {
    dispatch(getCoinSellerUniqueId(search));
  }, [search]);

  useEffect(() => {
    setData(userId);
  }, [userId]);

  useEffect(() => {
    setError({
      userId: "",
      coin: "",
    });
    setSearch("");
    setUniqueId("");
  }, [dialog]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createHost(agencyId, uniqueId));
    handleClose();
  };

  return (
    <>
      <Dialog
        open={dialog}
        aria-labelledby="responsive-dialog-title"
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        style={{
          zIndex: 9999999,
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            right: 0,
            color: "#000",
          }}
        >
          <Tooltip title="Close" placement="right">
            <Cancel className="cancelButton" onClick={handleClose} />
          </Tooltip>
        </IconButton>
        <DialogContent style={{ height: "250px" }}>
          <div class="modal-body pt-1 px-1 pb-3">
            <div class="d-flex flex-column">
              <form>
                <div class="form-group">
                  <div className="col-md-12 mt-2">
                    <label class="float-left dialog__input__title">
                      UserId
                    </label>

                    <input
                      type="number"
                      className="form-control"
                      placeholder="uniqueId"
                      value={uniqueId}
                      onChange={(e) => {
                        setUniqueId(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            uniqueId: "uniqueId is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            uniqueId: "",
                          });
                        }
                      }}
                      onKeyPress={(e) => {
                        ;
                        if (e.key === "Enter") {
                          if (e.target.value.length > 6) {
                            e.preventDefault();
                            setUniqueId(e.target.value);
                            setSearch(e.target.value);
                          }
                        }
                      }}
                    />
                    {data?.map((data) => {
                      return (
                        <>
                          <div className="country-option mt-3">
                            <img
                              src={data?.image}
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              alt="country-image"
                            />
                            <span className="ms-3">{data?.uniqueId}</span>
                          </div>
                        </>
                      );
                    })}
                    {/* <Select
                      ref={selectRef}
                      value={data?.find(
                        (option) => option?.uniqueId === uniqueId
                      )}
                      options={data}
                      getOptionLabel={(option) => option?.uniqueId} // Assuming uniqueId is the label for options
                      formatOptionLabel={(option) => (
                        <div className="country-option">
                          <img
                            src={option?.image}
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                            }}
                            alt="country-image"
                          />
                          <span className="ms-3">{option?.uniqueId}</span>
                        </div>
                      )}
                      onChange={(selectedOption) => {
                        setUniqueId(selectedOption?.uniqueId);

                        if (
                          !selectedOption?.uniqueId ||
                          selectedOption?.uniqueId === "uniqueId"
                        ) {
                          setError({
                            ...errors,
                            uniqueId: "Please select a uniqueId!",
                          });
                        } else {
                          setError({
                            ...errors,
                            uniqueId: "",
                          });
                        }
                      }}
                      onInputChange={(inputValue) => {
                        if (inputValue?.length > 6) {
                          setSearch(inputValue);
                          setIsDropdownOpen(true); // Open dropdown when input length is greater than 6
                        } else {
                          setIsDropdownOpen(false); // Close dropdown if input length is less than or equal to 6
                        }
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" &&
                          isDropdownOpen &&
                          selectRef.current
                        ) {
                          selectRef.current.select.openMenu("first"); // Open dropdown when Enter key is pressed
                        }
                      }}
                    /> */}
                  </div>
                </div>

                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  <button
                    className="btn-primary p-2"
                    style={{ border: "none" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connect(null, { createCoinData })(CoinSeller);
