import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminHistory } from "../../store/history/history.action";
import InfiniteScroll from "react-infinite-scroll-component";

const Income = ({ startDate, endDate }) => {
  const { history, summary, total } = useSelector((state) => state.history);
  const agencyId = localStorage.getItem("agencyId");

  console.log("history", history);
  console.log("summary", summary);

  const [hasMore, setHasMore] = useState(true);

  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    dispatch(
      getAdminHistory(agencyId, activePage, rowsPerPage, startDate, endDate)
    );
  }, [agencyId, activePage, rowsPerPage, startDate]);

  const fetchData = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        if (history.length < total) {
          setActivePage(activePage + 1);
          setRowsPerPage(rowsPerPage + 10);
          setLoadingMore(false);
        } else {
          setHasMore(false);
        }
      }, 500); // Adjust delay as needed
    }
  };

  return (
    <>
      {history?.length > 0 ? (
        <InfiniteScroll
          dataLength={history?.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            total > 10 ? (
              <p className="text-dark text-center">Loading...</p>
            ) : null
          }
          endMessage={<p className="text-center mt-2">No more items</p>}
        >
          <div className=" d-flex align-items-center">
            <h6 className="mb-0 me-1 text-dark">Agency Earning:</h6>
            <img
              src={require("../../assets/images/rcoin.png")}
              style={{ height: "20px", width: "20px" }}
              alt=""
            />
            <span
              className="mb-0 ms-1 fw-bolder"
              style={{ color: "#000", fontSize: "15px" }}
            >
              {summary?.totalCoin ? summary?.totalCoin : 0}
            </span>
          </div>
          <div className=" d-flex align-items-center">
            <h6 className="mb-0 me-1 text-dark">Creator Earning:</h6>
            <img
              src={require("../../assets/images/rcoin.png")}
              style={{ height: "20px", width: "20px" }}
              alt=""
            />
            <span
              className="mb-0 ms-1 fw-bolder"
              style={{ color: "#000", fontSize: "15px" }}
            >
              {summary?.hostCoin ? summary?.hostCoin?.toFixed(0) : 0}
            </span>
          </div>
          <div className="row">
            {history?.map((data) => {
              return (
                <>
                  <div className="col-12 mb-2 mt-1">
                    <div className="agency-invitation bg-white p-2">
                      <div className="row">
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                          Creator Earning
                          </span>
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/rcoin.png")}
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#ff8300",
                                fontSize: "15px",
                              }}
                            >
                              {data?.hostCoin ? data?.hostCoin : 0}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            My earning
                          </span>
                          <div className="">
                            <span
                              className="mb-0 ms-1 fw-bolder"
                              style={{
                                color: "#000",
                                fontSize: "15px",
                              }}
                            >
                              {data?.coin ? data?.coin + ` (${data.percent}%) ` : 0}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Date
                          </span>
                          <div className="d-flex">
                            <p
                              className="mb-0 ms-1 "
                              style={{
                                color: "#000",
                                fontSize: "12px",
                              }}
                            >
                              {data?.startDate} TO 
                            </p>
                              
                            <p
                              className="mb-0 ms-1 "
                              style={{
                                color: "#000",
                                fontSize: "12px",
                              }}
                            >
                              {data?.endDate}
                            </p>
                          </div>
                        </div>
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            Valid creators
                          </span>
                          <div className="d-flex">
                            <p
                              className="mb-0 ms-1 "
                              style={{
                                color: "#000",
                                fontSize: "12px",
                              }}
                            >
                              {data?.validCreator}  
                            </p>
 
                          </div>
                        </div>
                        <div className="col-4">
                          <span
                            style={{
                              color: "#a7a7a7",
                              fontSize: "8px",
                            }}
                          >
                            New creators
                          </span>
                          <div className="d-flex">
                            <p
                              className="mb-0 ms-1 "
                              style={{
                                color: "#000",
                                fontSize: "12px",
                              }}
                            >
                              {data?.newCreator}  
                            </p>
 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="d-flex justify-content-center align-items-center my-4">
          <span>No data found</span>
        </div>
      )}
    </>
  );
};

export default Income;
