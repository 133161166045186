import React, { useEffect, useState } from "react";

// js
import "../assets/js/main.min.js";

//router
import { NavLink as Link, useHistory } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

import "react-datepicker/dist/react-datepicker.css";

// component

import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/admin/action.js";
import male from "../assets/images/male.png";
import moment from "moment";
import { getUser } from "../store/user/action.js";
import { getAgencyCommission } from "../store/agencyCommission/action.js";
import leftArrow from "../assets/images/leftArrow.svg";

import {
  getAgencyEarning,
  getHosthistory,
} from "../store/history/history.action.js";
import CoinSeller from "../component/dialog/CoinSeller.js";
import { OPEN_COIN_SELLER_DIALOGUE } from "../store/seller/seller.type.js";
import { OPEN_NEW_REDEEM_DIALOG } from "../store/myRedeem/type.js";
import AgencyRedeemCreate from "../component/dialog/AgencyRedeemCreate.js";
import { permissionError } from "../util/Alert.js";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

const Admin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector((state) => state.admin.seller);
  const { user } = useSelector((state) => state.user);
  const earning = useSelector((state) => state.history);
  const { agencyCommission } = useSelector((state) => state.agencyCommission);
  const agencyId = localStorage.getItem("agencyId");
  const hostId = location?.state?.state;
  const [type, setType] = useState("gift");
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  console.log("admin", admin);

  useEffect(() => {
    dispatch(getProfile(agencyId, startDate, endDate));
    dispatch(getUser(agencyId));
    dispatch(getAgencyCommission());
    dispatch(getAgencyEarning(agencyId, startDate, endDate));
  }, [dispatch, agencyId, getAgencyEarning]);

  const handleOpenCreator = () => {
    if (admin?.isActive === false) return permissionError();
    dispatch({ type: OPEN_COIN_SELLER_DIALOGUE });
  };

  const handleBack = () => {
    window.showAndroidToast();
  };

  const handleOpenHostHistory = (id) => {
    history.push("/agencypanel/hosthistory", { state: id });
  };

  const handleOpenRedeem = () => {
    if (admin?.isActive === false) return permissionError();
    dispatch({ type: OPEN_NEW_REDEEM_DIALOG });
  };

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    dispatch(getHosthistory(hostId, type, activePage, rowsPerPage, start, end));
  };

  return (
    <>
      <div class="page-container">
        <div class="page-content">
          <div class="main-wrapper">
            <div className="main-section p-2">
              <div
                className="row p-3 bg-white  mb-2 "
                style={{
                  zIndex: "9",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                }}
              >
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleBack()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="col-4 text-center ">
                  <p
                    className="text-dark fw-bold mb-0"
                    style={{ fontSize: "18px" }}
                  >
                    Agency
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "65px" }}
              >
                <div className="bd-content">
                  <div className="d-flex">
                    <div>
                      <img
                        src={admin?.image ? admin?.image : male}
                        style={{
                          height: "40px",
                          width: "40px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <p
                        className="mb-0 fw-bolder text-dark"
                        style={{ fontSize: "15px" }}
                      >
                        {admin?.name ? admin?.name : "-"}
                      </p>
                      <p className="fw-bolder" style={{ fontSize: "12px" }}>
                        Code : {admin?.agencyCode ? admin?.agencyCode : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="income-detail">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className=" d-flex align-items-center">
                      <h6 className="mb-0 me-1 text-dark">Last month Income:</h6>
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {admin?.lastSettlementCoin ? admin?.lastSettlementCoin : 0}
                      </span>
                    </div>
                    <div className=" d-flex align-items-center">
                      <h6 className="mb-0 me-1 text-dark">Current month Coin:</h6>
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {admin?.currentCoin ? admin?.currentCoin.toFixed(0) : 0}
                      </span>

                    </div>
                    <div className=" d-flex align-items-center">
                      <h6 className="mb-0 me-1 text-dark">New creators :</h6>

                                           <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {admin?.newCreator ? admin?.newCreator: 0}
                      </span>

                    </div>

                  </div>

                </div>
                    <div className="d-flex  fs-6  ms-auto justify-content-end">
                    <Link to="/agencypanel/Income" className="text-dark">
                     Agency Settlement Details
                      <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </div>
                <div className="inner-income mt-2 text-center">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="mb-0 ms-1 fw-bolder"
                          style={{ color: "#000", fontSize: "15px" }}
                        >
                          {admin?.currentPercent ? admin?.currentPercent : 0} %
                        </span>
                      </div>
                      <span style={{ color: "#a7a7a7", fontSize: "10px" }}>
                        Commission from Creator
                      </span>
                      <div>
                        <button
                          className="btn-danger mt-2 px-2 py-1 rounded-pill"
                          style={{ border: "none" }}
                          onClick={() => handleOpenCreator()}
                        >
                          Add Creator
                        </button>
                      </div>
                    </div>
                    {/* <div
                      className="col-6"
                      style={{ borderLeft: "1px solid gray" }}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={require("../assets/images/rcoin.png")}
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                        />
                        <span
                          className="mb-0 ms-1 fw-bolder"
                          style={{ color: "#000", fontSize: "15px" }}
                        >
                          {admin?.withdrawableCoin
                            ? admin?.withdrawableCoin
                            : 0}
                        </span>
                      </div>
                      <span style={{ color: "#a7a7a7", fontSize: "10px" }}>
                        Total Redeem Amount
                      </span>
                      <div>
                        <button
                          className="btn-primary mt-2 px-2 py-1 rounded-pill"
                          style={{ border: "none", backgroundColor: "#0092ff" }}
                          onClick={handleOpenRedeem}
                        >
                          Add Redeem
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className=" d-flex align-items-center justify-content-center mt-2">
                    <h6 className="mb-0 me-1 text-dark">
                      {" "}
                      Creator Earning (This Month):
                    </h6>
                    <img
                      src={require("../assets/images/rcoin.png")}
                      style={{ height: "20px", width: "20px" }}
                      alt=""
                    />
                    <span
                      className="mb-0 ms-1 fw-bolder"
                      style={{ color: "#000", fontSize: "15px" }}
                    >
                      {admin?.currentHostCoin
                        ? admin?.currentHostCoin.toFixed(0)
                        : 0}
                    </span>
                  </div>

                </div>
              </div>

              <div className="agency-detail mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h4
                    className="text-dark fw-bold pt-2 "
                    style={{ fontSize: "16px" }}
                  >
                    Creator
                  </h4>

                  <div
                    className="d-flex align-items-center text-dark justify-content-center"
                    style={{ fontSize: "10px" }}
                  >
                    <Link
                      to="/agencypanel/creatorRequest"
                      className="text-dark"
                    >
                      Creator Request
                      <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </div>
                </div>

                <div className="agency-invitation mt-4 p-3 bg-white">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <h6 className="text-dark fw-bolder mb-0">
                        Commission Rate :
                        <span className="ms-2" style={{ color: "#ff8300" }}>
                          {admin?.currentPercent ? admin?.currentPercent : "0"}%
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div>Agency Target</div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      {agencyCommission &&
                        agencyCommission.map((data) => {
                          return <span>{data?.amountPercentage + "%"}</span>;
                        })}
                    </div>
                    <div
                      style={{ height: "5px", backgroundColor: "#ffbcff" }}
                    ></div>
                    <div className="d-flex align-items-center justify-content-between">
                      {agencyCommission &&
                        agencyCommission.map((data) => {
                          let formattedUpperCoin;
                          if (data?.upperCoin >= 1000000) {
                            formattedUpperCoin =
                              (data?.upperCoin / 1000000).toFixed() + "M";
                          } else if (data?.upperCoin >= 1000) {
                            formattedUpperCoin =
                              (data?.upperCoin / 1000).toFixed() + "K";
                          } else {
                            formattedUpperCoin = data?.upperCoin.toString();
                          }
                          return (
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "15px", width: "15px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{ color: "#000", fontSize: "12px" }}
                              >
                                {formattedUpperCoin ? formattedUpperCoin : 0}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className="agency-invitation p-3"
                    style={{ backgroundColor: "#ffeaff", borderRadius: "30px" }}
                  >
                    <div className="d-flex">
                      <div className="my-auto">
                        <i
                          class="fa-solid fa-chevron-up text-white p-2"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#e800ad",
                          }}
                        ></i>
                      </div>
                      <div
                        className=" ms-3"
                        style={{ verticalAlign: "center" }}
                      >
                        <p
                          className="mb-0  text-dark d-flex align-items-center"
                          style={{ fontSize: "15px", fontWeight: "500" }}
                        >
                          <span style={{ fontSize: "13px" }}>
                            This Month :
                          </span>
                          <img
                            src={require("../assets/images/rcoin.png")}
                            style={{ height: "20px", width: "20px" }}
                            alt=""
                          />
                          <span>
                            {admin?.currentCoin
                              ? admin?.currentCoin.toFixed(2)
                              : 0}{" "}
                            ({admin?.currentPercent}%)
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <h4
                    className="text-dark fw-bold pt-2 mt-2"
                    style={{ fontSize: "16px" }}
                  >
                    Creator details
                  </h4>
                  <div
                    className="d-flex align-items-center text-dark justify-content-center"
                    style={{ fontSize: "10px" }}
                  >
                    <Link to="/agencypanel/creators" className="text-dark">
                      Show More
                      <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </div>
                </div>

                {user?.length > 0 ? (
                  <>
                    {user.slice(0, 4).map((data, index) => {
                      return (
                        <>
                          <div className="agency-invitation mt-3 bg-white p-3">
                            <div className="d-flex">
                              <div>
                                <img
                                  src={data?.image ? data?.image : male}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  alt=""
                                />
                              </div>
                              <div className="ms-3">
                                <div className="d-flex">
                                  <h6
                                    className="mb-0 fw-bold text-dark"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {data?.name}
                                  </h6>
                                  <button
                                    className="btn-primary rounded ms-2"
                                    style={{
                                      border: "none",
                                      fontSize: "9px",
                                      backgroundColor: "#0092ff",
                                    }}
                                  >
                                    Creator
                                  </button>
                                </div>
                                <div className="d-flex">
                                  <p className="mb-0">ID : {data?.uniqueId}</p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className=" d-flex align-items-center mt-2">
                                <p
                                  className="mb-0 me-1 text-dark"
                                  style={{ fontSize: "11px" }}
                                >
                                  Coins:
                                </p>
                                <img
                                  src={require("../assets/images/rcoin.png")}
                                  style={{ height: "13px", width: "13px" }}
                                  alt=""
                                />
                                <span
                                  className="mb-0 ms-1 fw-bolder"
                                  style={{ color: "#ff8300", fontSize: "11px" }}
                                >
                                  {data?.thisMonthCoin ? data?.thisMonthCoin : 0}
                                </span>
                              </div>
                              <div
                                className="d-flex align-items-center text-dark justify-content-center"
                                style={{ fontSize: "10px" }}
                                onClick={() => handleOpenHostHistory(data?._id)}
                              >
                               Creator settlement History
                                <img
                                  src={leftArrow}
                                  alt=""
                                  height={10}
                                  width={10}
                                  style={{ marginLeft: "5px" }}
                                />
                              </div>
                            </div>

                            <span></span>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center my-4">
                      <span>No data found.</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoinSeller />
      <AgencyRedeemCreate />
    </>
  );
};

export default Admin;
